.edit-images{
  .image-item{
    img{
      width: 500px;
      height: 230px;
    }
    .form-group{
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
    }
  }
}